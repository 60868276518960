<template>
  <div class="grid lg:grid-cols-3 gap-8">
    <a
      v-for="row in rows"
      :key="row.name"
      class="h-128 bg-cover rounded-xl"
      :style="`background-image:url(${row.featuredImageUrl})`"
    >
      <div class="h-128 relative">
        <div class="absolute bottom-0 p-10 w-full bg-gradient-to-t from-black">
          <a
            :href="`/posts/${row.slug}`"
            class="text-slate-50 hover:underline text-2xl font-bold mb-5"
          >
            {{ row.title }}
          </a>
          <div
            class="text-gray-100 font-light"
            v-html="excerpt(row.content)"
          ></div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    rows: Array,
  },
  setup() {
    function excerpt(str) {
      // var str = "Killua Zoldyck is the best friend of Gon Freecss";
      // var output = document.getElementById("excerpt_area");
      /* if (str.length > 10) {
        str = str.substring(0, 10) + "...";
      } */
      return str.split(" ").slice(0, 20).join(" ");
    }

    return {
      excerpt,
    };
  },
};
</script>