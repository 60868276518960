export default function select2Data(
  data,
  keyName = null,
  valName = null,
  all = false
) {
  let lst = [];
  if (all) {
    lst.push({
      id: "-",
      text: "All", // + " " + element.description,
    });
  }
  if (keyName) {
    data.forEach((element) => {
      lst.push({
        id: element[keyName],
        text: element[valName], // + " " + element.description,
      });
    });
  } else {
    data.forEach((element) => {
      lst.push({
        id: element,
        text: element, // + " " + element.description,
      });
    });
  }

  return lst;
}
