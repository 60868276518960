<template>
  <section class="px-5 py-20 relative text-center animate__animated">
    <div class="container mx-auto">
      <div class="grid lg:grid-cols-4 gap-8">
        <div
          v-for="row in rows"
          :key="row.name"
          class="rounded-xl p-8 bg-brand-blue"
        >
          <div class="text-center flex flex-col items-center">
            <div class="text-white text-6xl mb-5">{{ row.count }}</div>
            <div class="text-slate-100 text-xl">{{ row.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="px-5 py-8 relative text-center">
    <div class="xl:w-1/2 mx-auto">
      <h1 class="text-4xl font-extrabold text-brand-blue mb-5">
        What is Coral Database?
      </h1>
      <div class="text-slate-500 text-lg font-light">
        <p>
          A platform to manage information and data collected concerning the
          state and health of coastal, oceanic, reef and other ecosystems found
          in the Maldives. It is a national tool that aims to empower people to
          drive and enact local, regional, and country wide change.
          <a
            href="/about-us"
            class="link"
            @click.prevent="$router.push('/page/about-us')"
            >Learn more</a
          >
        </p>
      </div>
    </div>
  </section>

  <section class="px-5 py-20 relative animate__animated">
    <div class="container mx-auto">
      <div class="grid lg:grid-cols-2 gap-8">
        <div
          v-for="row in fts"
          :key="row.name"
          class="lg:flex bg-gray-100 rounded-xl"
        >
          <div
            class="h-72 lg:w-2/3 bg-cover lg:rounded-l-xl lg:rounded-tr-none rounded-t-xl"
            :style="`background-image:url(${row.img})`"
          >
            <img class="rounded-l-xl hidden" :src="row.img" alt="" />
          </div>
          <div class="relative">
            <div class="p-10">
              <div class="mb-3">
                <a href="#" class="hover:underline text-xl font-bold">
                  {{ row.name }}
                </a>
              </div>
              <div class="font-light text-sm text-gray-500 mb-3">
                {{ row.content }}
                <!-- <a href="#" class="link">learn more</a>
                <ChevronRightIcon class="h-5 w-5 inline-block" /> -->
              </div>
              <div>
                <a :href="row.link" class="btn btn-sm btn-primary"
                  >{{ row.linkTitle }}
                  <ChevronRightIcon class="h-5 w-5 inline-block"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="px-5 py-20 relative animate__animated bg-gray-100">
    <div class="container mx-auto">
      <div class="grid lg:grid-cols-3 gap-8">
        <a
          v-for="(row, i) in section4"
          :key="i"
          class="lg:flex p-5 rounded-lg hover:bg-white"
          :href="row.link"
        >
          <div class="w-20 flex-shrink-0">
            <component :is="row.icon" class="w-16 h-16" />
          </div>
          <div>
            <h4 class="text-xl font-bold">{{ row.name }}</h4>
            <div class="text-gray-500 text-sm">{{ row.content }}</div>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>


<script>
import {
  ChevronRightIcon,
  DocumentReportIcon,
  ChartPieIcon,
  PhotographIcon,
} from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
export default {
  components: {
    ChevronRightIcon,
    DocumentReportIcon,
    ChartPieIcon,
    PhotographIcon,
  },
  setup() {
    let rows = ref([]);
    const store = useStore();

    store
      .dispatch("http/send", {
        module: "homePageStats",
      })
      .then((res) => {
        console.log(res.data);
        rows.value.push({
          name: "Monitory Sites",
          count: res.data.sites,
          icon: "",
        });
        rows.value.push({
          name: "Surveys",
          count: res.data.surveys,
          icon: "",
        });
        rows.value.push({
          name: "Organizations",
          count: res.data.organisations,
          icon: "",
        });
        rows.value.push({
          name: "Users",
          count: res.data.users,
          icon: "",
        });
      });

    const fts = [
      {
        name: "Collect, contribute, collaborate",
        img: "/pexels-francesco-ungaro-2289411.jpg",
        link: "https://dataportal.coraldatabase.gov.mv/",
        linkTitle: "Data portal",
        content:
          "Gather, compile, and manage your data with ease and efficiency ",
      },
      {
        name: "Analyze and assess",
        img: "/pexels-francesco-ungaro-3854025.jpg",
        link: "https://dashboard.coraldatabase.gov.mv/",
        linkTitle: "Dashboard",
        content:
          "Explore and visualize spatial and temporal distribution across localities and regions  ",
      },
    ];

    const section4 = [
      {
        name: "Protocols",
        content:
          "Access protocols that establish national standards for harmonized data collection",
        icon: DocumentReportIcon,
        link: "/protocols",
      },
      {
        name: "Reporting",
        content:
          "Report information about marine organisms, ecosystems, and events.",
        icon: ChartPieIcon,
        link: "/reporting",
      },
      {
        name: "Resources",
        content: "Download resources, reports, and publications",
        icon: PhotographIcon,
        link: "/documents",
      },
    ];

    return {
      rows,
      fts,
      section4,
    };
  },
};
</script>
