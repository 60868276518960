import axios from "axios";
// import router from "vue-router"
import store from "./store";

// window.axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use((config) => {
  // console.log(config)
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      // http status code
      const code = error.response.status;

      if (code == 401) {
        // eslint-disable-next-line
        console.log("Unauthorized");

        setTimeout(() => {
          store.commit("utils/showError", "Unauthorized");
          // document.location = process.env.VUE_APP_LOGIN_URL;
        }, 100);
        setTimeout(() => {
          // document.location = process.env.VUE_APP_LOGIN_URL;
        }, 5000);
        // document.location = process.env.VUE_APP_LOGIN_URL;
        // document.location = '/auth/login';
        // router.push('/auth/login')
      }
    } else {
      // console.log('Unable to reach server')
      store.commit("utils/showError", "There was an error, please try again");
    }

    return Promise.resolve(error);
  }
);
