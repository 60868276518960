<template>
  <span class="badge uppercase" :class="cssClass">{{ type }}</span>
</template>

<script>
export default {
  props: {
    type: String,
    css: {
      type: String,
      default: null,
    },
  },
  computed: {
    cssClass() {
      if (this.type == "Active") {
        return "badge-success";
      } else if (["Approved", "Paid"].includes(this.type)) {
        return "badge-success";
      } else if (["Pending Verification", "Paid"].includes(this.type)) {
        return "badge-info";
      } else if (["Cancelled", "Rejected"].includes(this.type)) {
        return "badge-danger";
      } else if (this.css) {
        return this.css;
      } else {
        return "default";
      }
    },
  },
};
</script>
