<template>
  <swiper
    :modules="modules"
    :direction="'vertical'"
    :pagination="false"
    :autoplay="autoplay"
    :navigation="false"
    class="mySwiper border-t"
    v-if="rows.length > 0"
  >
    <swiper-slide
      v-for="row in rows"
      :key="row.id"
      :style="`background-color:${row.webColourCode}`"
    >
      <div class="flex space-x-2 items-center">
        <span class="flex h-3 w-3">
          <span
            class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
            :class="[
              ['white', 'yellow'].includes(row.webColourCode)
                ? 'bg-red-600'
                : 'bg-white',
            ]"
          ></span>
          <span
            class="relative inline-flex rounded-full h-3 w-3"
            :class="[
              ['white', 'yellow'].includes(row.webColourCode)
                ? 'bg-red-600'
                : 'bg-white',
            ]"
          ></span>
        </span>
        <a
          v-if="row.url"
          :href="row.url"
          target="_blank"
          class="link hover:underline"
          :class="[
            ['white', 'yellow'].includes(row.webColourCode)
              ? 'text-black'
              : 'text-white',
          ]"
        >
          {{ row.name }}: {{ row.message }}
          <ChevronRightIcon class="w-5 h-5 inline-block"
        /></a>
        <span v-else>{{ row.name }}: {{ row.message }}</span>
      </div>
    </swiper-slide>
    <!-- <swiper-slide>Slide 2</swiper-slide>
    <swiper-slide>Slide 3</swiper-slide> -->
  </swiper>
</template>

<script setup>
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

const store = useStore();
const rows = computed(() => store.getters["alert/data"].rows);

store.dispatch("http/send", {
  module: "alert",
});
const autoplay = {
  delay: 2000,
};
const pagination = {
  clickable: true,
};

const modules = [Autoplay];

/* const rows = [
  {
    id: 1,
    name: "Ongoing monitoring of coral bleaching, learn more",
  },
  {
    id: 2,
    name: "Another alert",
  },
]; */
</script>

<style scoped>
.swiper {
  @apply w-full h-16 bg-yellow-500 text-white text-sm;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>