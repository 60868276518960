import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsView.vue"),
  },
  {
    path: "/page/:slug",
    name: "page",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PageView.vue"),
  },
  {
    path: "/posts",
    name: "posts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Posts/HomeView.vue"),
  },
  {
    path: "/posts/:slug",
    name: "post",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Posts/DetailsView.vue"),
  },

  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsView.vue"),
  },
  {
    path: "/documents",
    name: "docs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DocsView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FaqView.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HelpView.vue"),
  },
  {
    path: "/protocols",
    name: "protocols",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProtocolsView.vue"),
  },
  {
    path: "/reporting",
    name: "reporting",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reporting/HomeView.vue"),
  },
  {
    path: "/reporting/:requestid/edit",
    name: "editReport",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "reporting" */ "../views/reporting/create/ReportingForm.vue"
      ),
  },
  {
    path: "/reporting/:reference",
    name: "viewReport",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "reporting" */ "../views/reporting/details/IndexView.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
