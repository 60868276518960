<template>
  <div
    class="
      fullscrctn
      w-100
      h-100
      position-fixed
      d-flex
      flex-col
      justify-content-center
      align-items-center
    "
    style="z-index: 2000; background: rgba(0, 0, 0, 0.1)"
  >
    <div class="p-3 shadow-lg rounded bg-white text-center">
      <SpinnerComponent class="mx-auto" />
      <div v-if="$store.getters['utils/msg']" class="mt-2">
        {{ $store.getters["utils/msg"] }}
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerComponent from "./SpinnerComponent";
export default {
  components: {
    SpinnerComponent,
  },
};
</script>

<style scoped>
.overlay {
  background: rgba(0, 0, 0, 0.5);
}
</style>