const state = {
  loading: false,
  loadingGlobal: false,
  msg: null,
  error: false,
  info: false,
  success: false,
  action: null,
  hasAlert: false,
  feedback: false,
  feedbackMsg: true,
  route: {
    meta: {
      showBackBtn: false,
    },
  },
};

const getters = {
  loading: (state) => state.loading,
  loadingGlobal: (state) => state.loadingGlobal,
  error: (state) => state.error,
  msg: (state) => state.msg,
  action: (state) => state.action,
  hasAlert: (state) => state.hasAlert,
  route: (state) => state.route,
  success: (state) => state.success,
  feedback: (state) => state.feedback,
  feedbackMsg: (state) => state.feedbackMsg,
};

const actions = {
  //
};

const mutations = {
  load(state) {
    state.loading = true;
    state.error = false;
  },
  loadingGlobal(state) {
    state.loadingGlobal = true;
    state.error = false;
  },
  loadedGlobal(state) {
    state.loadingGlobal = false;
    state.error = false;
  },
  loaded(state) {
    state.loading = false;
    state.msg = null;
  },
  msg(state, msg) {
    state.msg = msg;
  },
  error(state) {
    state.loading = false;
    state.error = true;
  },
  showError(state, payload) {
    state.loading = false;
    state.msg = payload;
    state.error = true;
  },
  hideError(state) {
    state.error = false;
    state.msg = null;
  },
  showAlert(state) {
    state.hasAlert = true;
    // state.action = action;
  },
  hideAlert(state) {
    state.msg = null;
    state.hasAlert = false;
  },
  success(state) {
    state.success = true;
  },
  hideSuccess(state) {
    state.success = false;
  },
  /* feedback */
  feedback(state, payload) {
    state.feedback = true;
    state.feedbackMsg = payload;
  },
  hideFeedback(state) {
    state.feedback = false;
    state.feedbackMsg = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
