<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Popover class="bg-white sticky top-0 left-0 w-full z-50 px-5" id="header">
    <div class="max-w-7xl mx-auto">
      <div
        class="
          flex
          justify-between
          items-center
          py-6
          md:justify-start md:space-x-10
        "
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <a href="/">
            <span class="sr-only">CoralDB</span>
            <img class="h-8 w-auto sm:h-10" src="/brand.png" alt="" />
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500 hover:bg-gray-100
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-indigo-500
            "
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden md:flex space-x-10">
          <a
            href="/"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Home
          </a>
          <!-- <Popover class="relative" v-slot="{ open }">
            <PopoverButton
              :class="[
                open ? 'text-gray-900' : 'text-gray-500',
                'group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              ]"
            >
              <span>Services</span>
              <ChevronDownIcon
                :class="[
                  open ? 'text-gray-600' : 'text-gray-400',
                  'ml-2 h-5 w-5 group-hover:text-gray-500',
                ]"
                aria-hidden="true"
              />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
            >
              <PopoverPanel
                class="
                  absolute
                  z-10
                  -ml-4
                  mt-3
                  transform
                  px-2
                  w-screen
                  max-w-md
                  sm:px-0
                  lg:ml-0 lg:left-1/2 lg:-translate-x-1/2
                "
              >
                <div
                  class="
                    rounded-lg
                    shadow-lg
                    ring-1 ring-black ring-opacity-5
                    overflow-hidden
                  "
                >
                  <div
                    class="
                      relative
                      grid
                      gap-6
                      bg-white
                      px-5
                      py-6
                      sm:gap-8 sm:p-8
                    "
                  >
                    <a
                      v-for="item in services"
                      :key="item.name"
                      :href="item.href"
                      class="
                        -m-3
                        p-3
                        flex
                        items-start
                        rounded-lg
                        hover:bg-gray-50
                      "
                    >
                      <component
                        :is="item.icon"
                        class="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover> -->

          <!-- <Popover class="relative" v-slot="{ open }">
            <PopoverButton
              :class="[
                open ? 'text-gray-900' : 'text-gray-500',
                'group  bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              ]"
            >
              <span>Apps</span>
              <ChevronDownIcon
                :class="[
                  open ? 'text-gray-600' : 'text-gray-400',
                  'ml-2 h-5 w-5 group-hover:text-gray-500',
                ]"
                aria-hidden="true"
              />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
            >
              <PopoverPanel
                class="
                  absolute
                  z-10
                  -ml-4
                  mt-3
                  transform
                  px-2
                  w-screen
                  max-w-md
                  sm:px-0
                  lg:ml-0 lg:left-1/2 lg:-translate-x-1/2
                "
              >
                <div
                  class="
                    rounded-lg
                    shadow-lg
                    ring-1 ring-black ring-opacity-5
                    overflow-hidden
                  "
                >
                  <div
                    class="
                      relative
                      grid
                      gap-6
                      bg-white
                      px-5
                      py-6
                      sm:gap-8 sm:p-8
                    "
                  >
                    <a
                      v-for="item in apps"
                      :key="item.name"
                      :href="item.href"
                      class="
                        -m-3
                        p-3
                        flex
                        items-start
                        rounded-lg
                        hover:bg-gray-50
                      "
                    >
                      <component
                        :is="item.icon"
                        class="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover> -->

          <a
            href="/documents"
            @click.prevent="$router.push('/documents')"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Resources
          </a>
          <a
            href="/reporting"
            @click.prevent="$router.push('/reporting')"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Reporting
          </a>
          <a
            href="/protocols"
            @click.prevent="$router.push('/protocols')"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Protocols
          </a>
          <a
            href="/faq"
            @click.prevent="$router.push('/faq')"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            FAQ
          </a>
          <a
            href="/posts"
            @click.prevent="$router.push('/posts')"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Blog
          </a>
          <!-- <a
            href="/contact"
            @click.prevent="$router.push('/contact')"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Contact
          </a> -->
        </PopoverGroup>
        <div
          class="
            hidden
            md:flex
            items-center
            justify-end
            md:flex-1
            lg:w-0
            space-x-2
          "
        >
          <a
            :href="dashboardUrl"
            target="_blank"
            class="btn btn-primary btn-sm"
          >
            <span class="text-sm"
              ><ChartBarIcon class="w-4 h-4 inline-block" /> Dashboard</span
            >
          </a>
          <a :href="adminUrl" class="btn btn-primary btn-sm">
            <span class="text-sm"
              ><ViewGridIcon class="w-4 h-4 inline-block" /> Login to data
              portal</span
            >
          </a>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="
          absolute
          top-0
          inset-x-0
          p-2
          transition
          transform
          origin-top-right
          md:hidden
        "
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="/brand.png" alt="CoralDB" />
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="
                    bg-white
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    text-gray-400
                    hover:text-gray-500 hover:bg-gray-100
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-indigo-500
                  "
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <!-- <div class="mt-6">
              <nav class="grid gap-y-8">
                <a
                  v-for="item in solutions"
                  :key="item.name"
                  :href="item.href"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 h-6 w-6 text-indigo-600"
                    aria-hidden="true"
                  />
                  <span class="ml-3 text-base font-medium text-gray-900">
                    {{ item.name }}
                  </span>
                </a>
              </nav>
            </div> -->
          </div>
          <div class="py-6 px-5 space-y-6">
            <div class="grid grid-cols-2 gap-y-4 gap-x-8">
              <a
                href="/documents"
                @click.prevent="$router.push('/documents')"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Resources
              </a>

              <a
                href="/reporting"
                @click.prevent="$router.push('/reporting')"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Reporting
              </a>
              <a
                href="/protocols"
                @click.prevent="$router.push('/protocols')"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Protocols
              </a>
              <a
                href="/faq"
                @click.prevent="$router.push('/faq')"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                FAQ
              </a>
              <a
                href="/posts"
                @click.prevent="$router.push('/posts')"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Blog
              </a>
              <a
                href="/contact"
                @click.prevent="$router.push('/contact')"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Contact us
              </a>
            </div>
            <div class="">
              <a
                :href="dashboardUrl"
                target="_blank"
                class="btn btn-primary btn-sm"
              >
                <span class="text-sm"
                  ><ChartBarIcon class="w-4 h-4 inline-block" /> Dashboard</span
                >
              </a>
              <a :href="adminUrl" class="btn btn-primary btn-sm">
                <span class="text-sm"
                  ><ViewGridIcon class="w-4 h-4 inline-block" /> Login to data
                  portal</span
                >
              </a>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  BookmarkAltIcon,
  CalendarIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  SupportIcon,
  XIcon,
  ClipboardCheckIcon,
  BeakerIcon,
  OfficeBuildingIcon,
  GlobeAltIcon,
  LocationMarkerIcon,
  DatabaseIcon,
  ChartBarIcon,
  ViewGridIcon,
} from "@heroicons/vue/outline";
// import { ChevronDownIcon } from "@heroicons/vue/solid";
import { onMounted } from "@vue/runtime-core";

const services = [
  {
    name: "Fishing license",
    description: "Apply for collector license",
    href: "#",
    icon: ClipboardCheckIcon,
  },
  {
    name: "Processing License",
    description: "Apply and renew processing licenses",
    href: "#",
    icon: BeakerIcon,
  },
  {
    name: "Catch certificate",
    description: "Apply for new Catch certificate",
    href: "#",
    icon: ShieldCheckIcon,
  },
  /* {
    name: "Ice plants",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: ViewGridIcon,
  }, */
];

const apps = [
  {
    name: "Processors",
    description: "Portal for fish processors reporting",
    href: "#",
    icon: OfficeBuildingIcon,
  },
  {
    name: "Fishers portal",
    description: "Portal for fishing vessels",
    href: "#",
    icon: GlobeAltIcon,
  },
  {
    name: "FAD list",
    description: "Locate FADs around the country",
    href: "#",
    icon: LocationMarkerIcon,
  },
  {
    name: "Ice plants",
    description: "Locate ice plants around the country",
    href: "#",
    icon: DatabaseIcon,
  },
];
const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];
const resources = [
  {
    name: "Help Center",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "#",
    icon: SupportIcon,
  },
  {
    name: "Guides",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "#",
    icon: BookmarkAltIcon,
  },
  {
    name: "Events",
    description:
      "See what meet-ups and other events we might be planning near you.",
    href: "#",
    icon: CalendarIcon,
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously.",
    href: "#",
    icon: ShieldCheckIcon,
  },
];
const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    // ChevronDownIcon,
    MenuIcon,
    XIcon,
    ChartBarIcon,
    ViewGridIcon,
  },
  setup() {
    const dashboardUrl = process.env.VUE_APP_DASHBOARD_URL;
    const adminUrl = process.env.VUE_APP_ADMIN_URL;
    onMounted(() => {
      document.addEventListener("scroll", function () {
        // lastKnownScrollPosition = window.scrollTop;
        // console.log(window.scrollY);
        if (window.scrollY > 10) {
          document.getElementById("header").classList.add("shadow-md");
        } else {
          document.getElementById("header").classList.remove("shadow-md");
        }
      });
    });
    return {
      services,
      apps,
      callsToAction,
      resources,
      recentPosts,
      dashboardUrl,
      adminUrl,
    };
  },
};
</script>