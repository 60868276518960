<template>
  <section class="px-5 py-20 relative animate__animated bg-black">
    <div class="container mx-auto">
      <h1 class="text-2xl font-bold text-brand-blue mb-5">
        Recent posts <ChevronRightIcon class="inline-block h-6 w-6" />
      </h1>
      <PostCardComponent :rows="rows" />
    </div>
  </section>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/solid";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import PostCardComponent from "./PostCardComponent";
export default {
  components: {
    ChevronRightIcon,
    PostCardComponent,
  },
  setup() {
    const store = useStore();
    const rows = computed(() => store.getters["post/data"].rows);

    store.dispatch("http/send", {
      module: "post",
      params: {
        recentOnly: true,
      },
    });
    /* const rows = [
      {
        name: "Manage, analyse & share monitoring data",
        img: "/pexels-francesco-ungaro-3854025.jpg",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque convallis imperdiet mi maximus pulvinar.",
      },
      {
        name: "Fast-track analysis",
        img: "/pexels-francesco-ungaro-3361052.jpg",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque convallis imperdiet mi maximus pulvinar.",
      },
      {
        name: "Collaborate, communicate & conserve",
        img: "/pexels-francesco-ungaro-2289411.jpg",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque convallis imperdiet mi maximus pulvinar.",
      },
    ]; */

    function excerpt(str) {
      // var str = "Killua Zoldyck is the best friend of Gon Freecss";
      // var output = document.getElementById("excerpt_area");
      /* if (str.length > 10) {
        str = str.substring(0, 10) + "...";
      } */
      return str.split(" ").slice(0, 20).join(" ");
    }

    return {
      rows,
      excerpt,
    };
  },
};
</script>
