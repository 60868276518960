<template>
  <div
    @keyup.esc="hideError"
    class="top-0 p-5 left-0 h-full w-full fixed flex justify-center items-center"
    style="z-index: 2000; background: rgba(0, 0, 0, 0.2)"
  >
    <div>
      <div
        class="flex justify-center items-center flex-col mb-5 bg-white shadow-2xl p-12 rounded-lg"
      >
        <div class="mb-5">
          <svg
            height="32"
            style="overflow: visible; enable-background: new 0 0 32 32"
            viewBox="0 0 32 32"
            width="32"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <g id="Error_1_">
                <g id="Error">
                  <circle
                    cx="16"
                    cy="16"
                    id="BG"
                    r="16"
                    style="fill: #d72828"
                  />
                  <path
                    d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                    id="Exclamatory_x5F_Sign"
                    style="fill: #e6e6e6"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="mb-5 text-center text-gray-700">
          <div v-if="$store.getters['utils/msg']" class="mt-2">
            {{ $store.getters["utils/msg"] }}
          </div>
          <div v-else>We have encountered an error. Please try again.</div>
        </div>
        <div>
          <button class="btn btn-danger" @click="hideError">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    function hideError() {
      store.commit("utils/hideError");
    }
    return {
      hideError,
    };
  },
};
</script>
