<template>
  <NavMenu />
  <AlertsComponent />
  <HeroVideo />
  <SectionOne />
  <!-- <SectionTwo /> -->
  <SectionThree />
  <SectionFooter />
</template>

<script>
import NavMenu from "@/components/NavMenu";
import HeroVideo from "@/components/HeroVideo";
import AlertsComponent from "@/components/AlertsComponent";
import SectionOne from "@/components/SectionOne";
// import SectionTwo from "@/components/SectionTwo";
import SectionThree from "@/components/SectionThree";
import SectionFooter from "@/components/SectionFooter";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {
    HeroVideo,
    AlertsComponent,
    NavMenu,
    SectionOne,
    // SectionTwo,
    SectionThree,
    SectionFooter,
  },
  setup() {
    onMounted(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("down")) {
              entry.target.classList.add("animate__fadeInDown");
            } else if (entry.target.classList.contains("zoom")) {
              entry.target.classList.add("animate__zoomIn");
            } else {
              entry.target.classList.add("animate__fadeInUp");
            }
          }
        });
      });

      const boxElList = document.querySelectorAll(".animate__animated");
      boxElList.forEach((el) => {
        observer.observe(el);
      });
    });
  },
};
</script>
