<template>
  <button
    class="btn btn-primary"
    :disabled="$store.getters[`${storeModule}/loading`]"
  >
    <SpinnerSmall v-if="$store.getters[`${storeModule}/loading`]" />
    <span v-else>{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    storeModule: String,
    text: {
      type: String,
      default: "Submit",
    },
  },
  setup() {},
};
</script>