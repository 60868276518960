<template>
  <div
    class="relative bg-cover"
    style="background-image: url(/pexels-belle-co-847393.jpg)"
  >
    <div class="relative xl:h-220 overflow-hidden">
      <div class="absolute z-10 w-full h-full lg:bg-black opacity-40"></div>
      <div class="absolute z-20 w-full h-full flex justify-center items-center">
        <div
          class="w-1/2 mx-auto text-center"
          style="text-shadow: 1px 1px 1px #000"
        >
          <h1 class="xl:text-6xl text-3xl font-extrabold text-white mb-5">
            The Coral Database
          </h1>
          <div class="text-xl text-slate-200 mb-10">
            Collaborative monitoring of coastal, oceanic, reef and other
            ecosystems
          </div>
          <div>
            <a :href="dashboardUrl" target="_blank" class="btn btn-primary"
              >Launch</a
            >
          </div>
        </div>
      </div>
      <video
        class="w-full relative aspect-video hidden lg:block"
        id="vid"
        autoplay
        muted
        loop
      >
        <source src="/pexels-adrien-jacta-5358755.mp4" type="video/mp4" />
        <source src="/pexels-adrien-jacta-5358755.ogg" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <div class="h-140 lg:hidden"></div>
    </div>
  </div>
</template>

<script setup>
const dashboardUrl = process.env.VUE_APP_DASHBOARD_URL;
// const adminUrl = process.env.VUE_APP_ADMIN_URL;
</script>