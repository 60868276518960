import axios from "axios";
// import { response } from 'express';

const actions = {
  async load({ rootGetters }, payload) {
    /* try {
            // console.log(rootGetters[`${payload.module}/baseUrl`]);
            // let url = rootGetters[`${payload.module}/${payload.property}`].url || "";
            let response = await axios({
                method: payload.method || 'GET',
                url: rootGetters[`${payload.module}/baseUrl`],
                data: payload.data || {},
                params: payload.params || {}
            });
            console.log("Loading")
            return response;
        } catch (error) {
            // reject(response.data.message);
            // console.log(e);
        } */
    return new Promise((resolve, reject) => {
      axios({
        method: payload.method || "GET",
        url: rootGetters[`${payload.module}/baseUrl`],
        data: payload.data || {},
        params: payload.params || {},
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error.response.request.response);
          reject(error);
        });
    });
  },

  async send({ commit, dispatch }, payload) {
    let commitData = true;
    if (typeof payload.commit !== "undefined") {
      commitData = payload.commit;
    }
    let key = payload.key || "rows";
    return new Promise((resolve, reject) => {
      commit("utils/load", null, { root: true });
      commit(`${payload.module}/loading`, true, { root: true });
      commit(`${payload.module}/error`, false, { root: true });
      dispatch("load", payload)
        .then((response) => {
          commit("utils/loaded", null, { root: true });
          commit(`${payload.module}/loading`, false, { root: true });
          if (response == undefined) {
            commit(`${payload.module}/error`, true, { root: true });
            commit("utils/showError", "There was an error, please try again", {
              root: true,
            });
            reject("There was an error, please try again");
          } else if (
            response.data.isSuccess &&
            response.data.statusCode == 200
          ) {
            // let commitData = payload.commit || true;
            // let key = payload.key || "rows";
            // let property = payload.property || "data";
            if (commitData) {
              commit(
                `${payload.module}/mutate`,
                {
                  // property: property,
                  with: response.data.data,
                  key: key,
                },
                { root: true }
              );
            }
            resolve(response.data);
          } else {
            commit(`${payload.module}/error`, true, { root: true });
            commit("utils/showError", response.data.message, { root: true });
            reject(response.data);
          }
        })
        .catch((error) => {
          commit(`${payload.module}/error`, true, { root: true });
          commit(`${payload.module}/loading`, false, { root: true });
          commit("utils/showError", "There was an error, please try again", {
            root: true,
          });
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
