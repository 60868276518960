<template>
  <div
    class="
      loader
      ease-linear
      rounded-full
      border-4 border-t-4 border-gray-200
      h-8
      w-8
    "
  ></div>
</template>

<style scoped>
.loader {
  border-top-color: #006980;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
