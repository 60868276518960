import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./init";
import "./assets/css/site.css";
import UntilComponents from "./components/utils";
import NavMenu from "@/components/NavMenu";
import SectionFooter from "@/components/SectionFooter";
import select2Data from "./utils";

const app = createApp(App);

app.use(store);
app.use(router);
app.component("NavMenu", NavMenu);
app.component("SectionFooter", SectionFooter);
UntilComponents.register(app);
app.config.globalProperties.select2Data = select2Data;

app.mount("#app");
