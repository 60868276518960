<template>
  <div @click.self="overlayClick" class="bottom-5 left-5 z-50 fixed pb-8 pl-8">
    <div class="py-3 px-8 bg-black text-white shadow-lg rounded-lg">
      {{ $store.getters["utils/feedbackMsg"] }}
    </div>
  </div>
</template>

<script>
var closeTimeOut;
export default {
  methods: {
    hideError() {
      this.$store.commit("utils/hideFeedback");
    },
  },
  created() {
    closeTimeOut = setTimeout(this.hideError, 5000);
    /* window.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.$store.commit("utils/hideFeedback");
      }
    }); */
  },
  beforeUnmount() {
    clearTimeout(closeTimeOut);
    // window.removeEventListener("keydown", null);
  },
};
</script>