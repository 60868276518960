const state = {
    baseUrl: "/api/website/faqsearch",
    data: {
        rows: [],
        row: {},
    },
    loaded: false,
    loading: false,
    error: false,
};

const getters = {
    baseUrl: (state) => state.baseUrl,
    data: (state) => state.data,
    loaded: (state) => state.loaded,
    loading: (state) => state.loading,
    error: (state) => state.error,
};

const actions = {};

const mutations = {
    mutate(state, payload) {
        let key = payload.key || "rows";
        state["data"][key] = payload.with;
        if (key == "rows") {
            state.loaded = true;
        }
    },
    loading(state, payload) {
        state.loading = payload;
    },
    error(state, payload) {
        state.error = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
